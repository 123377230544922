import React, { createContext, useState } from "react"

interface Theme {
    colors: Colors,
    setColors: React.Dispatch<React.SetStateAction<Colors>>
}

interface Colors {
    warningColor: string,
    errorColor: string,
    resultColor: string,
    notRequiredColor: string,
    primaryColor: string
}

const defaultColors:Colors = {
    warningColor: '#FBBC05',
    errorColor: '#fc7b54',
    resultColor: '#BEE2E9',
    notRequiredColor: '#D9D9D9',
    primaryColor: '#164194'
}

const defaultTheme:Theme = {
    colors: defaultColors,
    setColors: () => { }
};

export const ThemeContext = createContext<Theme>(defaultTheme);

interface ThemeProviderProps {
    children: React.ReactNode
}

export default function ThemeProvider({children}:ThemeProviderProps) {
    let [colors, setColors] = useState<Colors>(defaultColors);

    return (
        <ThemeContext.Provider value={{colors, setColors}}>
            {children}
        </ThemeContext.Provider>
    )
}