import { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGridHeader } from './DataGridHeader';
import DataGridRow from './DataGridRow';
import { instantiateNewRow, sortRows } from '../../model/Row';
import { StateContext } from '../../providers/StateProvider';
import { findAllRowsOnLocalStorage, updateRowsOnLocalStorage } from '../../services/RowService';

export default function DataGrid() {
    let { rows, setRows } = useContext(StateContext);

    /**
     * Load rows from the local storage.
     */
    useEffect(() => {
        const allRows = findAllRowsOnLocalStorage();
        if(allRows.length === 0) allRows.push(instantiateNewRow([]));

        setRows(allRows);
    }, [setRows]);

    const onNewLineButtonClick = () => {
        const allRows = findAllRowsOnLocalStorage();
        const newRow = instantiateNewRow(allRows);

        allRows.push(newRow);

        sortRows(allRows);
        setRows(allRows);
        updateRowsOnLocalStorage(allRows);
    }

    const onPrintButtonClick = () => {
        const url = window.origin + '/print';
        window.open(url, '_blank');
    }

    return (
        <div>
            <table style={{ borderSpacing: 0 }}>
                <thead>
                    <DataGridHeader variant='default' />
                </thead>

                <tbody>
                {
                    rows.map((element, index) =>
                        <DataGridRow data={element} key={index} />
                    )
                }
                </tbody>
                <tfoot>
                    <tr>
                        <td align='right' colSpan={13} >
                            <Stack direction='row' spacing={2} marginTop={2} justifyContent='flex-end'>
                                <Button
                                    onClick={onPrintButtonClick}
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#E0E0E0',
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    }}
                                    id='print_button'
                                >
                                    print
                                </Button>
                                <Button
                                    onClick={onNewLineButtonClick}
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#164194'
                                    }}
                                    id='new_line_button'
                                >
                                    new line
                                </Button>
                            </Stack>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}