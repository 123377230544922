import { StyledEngineProvider } from '@mui/material/styles';
import BufferCalc from './components/BufferCalc';
import ThemeProvider from './providers/ThemeProvider';

function App() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <BufferCalc />
        </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;
