import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataGrid from '../DataGrid';

export default function DataGridWrapper() {
    return (
        <Accordion defaultExpanded sx={{margin: 0, minHeight: 0}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
            >
                <Typography variant="h5">Samples</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <DataGrid />
            </AccordionDetails>
        </Accordion>
    );
}