import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Row } from '../../model/Row';
import Fields from '../Fields';
import { ThemeContext } from '../../providers/ThemeProvider';

interface EditSampleDialogProps {
    row: Row | null,
    open: boolean,
    onClose: () => void
}

export default function EditSampleDialog(props:EditSampleDialogProps) {
    const { colors } = useContext(ThemeContext);
    const [dialogTitle, setDialogTitle] = useState<string>('');

    if(props.row === null) return <></>

    return (
        <>
            <Dialog
                fullScreen
                open={props.open}
                onClose={props.onClose}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar sx={{backgroundColor: colors.primaryColor}}>
                        <Stack direction='row' justifyContent='space-between' sx={{width: '100%'}}>
                            <Typography variant='h6'>{dialogTitle}</Typography>
                            <IconButton
                                edge='start'
                                color='inherit'
                                aria-label='close'
                                onClick={props.onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>

                <Stack direction='column' spacing={2} padding={5}>
                    <Fields
                        data={props.row}
                        onSampleDescriptionChanged={(sd) => setDialogTitle(sd)}
                    />
                </Stack>
            </Dialog>
        </>
    );
}