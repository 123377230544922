export function validateNumber(number:string):void {
    if(typeof number !== 'number')
        if(Number.isNaN(Number(number.replace(',', '.')))) throw `Número inválido.`;
}

export function toNumber(number:string):number {
    if(typeof number === 'number') return number;

    validateNumber(number);
    return Number(number.replace(',', '.'));
}

export function toMonetary(number:number):string {
    return `R$${number.toFixed(2).replace('.', ',')}`;
}