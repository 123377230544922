import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    BufferConcentrationUnit,
    BufferConcentrationUnits,
    BufferType,
    BufferTypes,
    calculateBuffer,
    DensityUnit,
    DensityUnits,
    Fluid,
    Fluids,
    mustAcidBeAdded,
    mustKohBeAdded,
    shouldAcidBeAdded,
    shouldKohBeAdded
} from '@fastsheep/buffercalc-core';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { StateContext } from '../../providers/StateProvider';
import { toNumber } from '../../utils/NumberUtils';
import FieldContainer from '../FieldContainer';
import { instantiateNewRowWithData, Row } from '../../model/Row';
import { PhColor } from '../../model/PhColor';
import { ThemeContext } from '../../providers/ThemeProvider';
import {
    DefaultUm,
    Gcm3,
    Kgm3,
    Lbbbl,
    Lbgal,
    Moll,
    PotassiumBicarbonate,
    PotassiumCarbonate,
    Pptf,
    SodiumBicarbonate,
    SodiumCarbonate
} from '../Units';
import { findAllRowsOnLocalStorage, updateRowsOnLocalStorage } from '../../services/RowService';

const PH_ADJUSTMENT_REQUIRED_MESSAGE = 'pH adjustment required';
const PH_ADJUSTMENT_RECOMMENDED_MESSAGE = 'pH adjustment recommended';

const pHFieldsAreValid = (originalPh: string, increasedPh: string, reducedPh: string):boolean => {
    if(originalPh.length === 0) return false;

    const _mustAcidBeAdded = mustAcidBeAdded(toNumber(originalPh));
    const _shouldAcidBeAdded = shouldAcidBeAdded(toNumber(originalPh));
    const _mustKohBeAdded = mustKohBeAdded(toNumber(originalPh));
    const _shouldKohBeAdded = shouldKohBeAdded(toNumber(originalPh));

    if(_mustAcidBeAdded && reducedPh.length === 0) return false;
    if(_mustKohBeAdded && increasedPh.length === 0) return false;
    if(_shouldAcidBeAdded && reducedPh.length === 0) return true;
    if(_shouldKohBeAdded && increasedPh.length === 0) return true;

    if((_mustAcidBeAdded || _shouldAcidBeAdded) && mustAcidBeAdded(toNumber(reducedPh))) return false;
    if((_mustAcidBeAdded || _shouldAcidBeAdded) && mustKohBeAdded(toNumber(reducedPh))) return false;

    if((_mustKohBeAdded || _shouldKohBeAdded) && mustAcidBeAdded(toNumber(increasedPh))) return false;
    if((_mustKohBeAdded || _shouldKohBeAdded) && mustKohBeAdded(toNumber(increasedPh))) return false;

    return true;
}

interface FieldsProps {
    data: Row,
    onOriginalPhErrorChanged?: (phError: string) => void,
    onPhAfterKOHAdjustmentDisabledChanged?: (value: boolean) => void,
    onPhAfterAcidAdjustmentDisabledChanged?: (value: boolean) => void,
    onAllRequiredFieldsFilledChanged?: (value: boolean) => void,
    onSampleDescriptionChanged?: (value: string) => void,
    onOriginalPhColorChanged?: (value: PhColor) => void,
    onIncreasedPhErrorChanged?: (increasedPhError: string) => void,
    onReducedPhErrorChanged?: (reducedPhError: string) => void,
    onIncreasedPhColorChanged?: (value: PhColor) => void,
    onReducedPhColorChanged?: (value: PhColor) => void
}

export default function Fields(props:FieldsProps) {
    const {
        bufferType,
        bufferConcentrationUnit,
        densityUnit
    } = useContext(StateContext);

    const { colors } = useContext(ThemeContext);

    //#region state
    const [phAfterAcidAdjustmentDisabled, setPhAfterAcidAdjustmentDisabled] = useState<boolean>(true);
    const [phAfterKOHAdjustmentDisabled, setPhAfterKOHAdjustmentDisabled] = useState<boolean>(true);
    const [sampleDescription, setSampleDescription ] = useState<string>('Sample');
    const [blendType, setBlendType] = useState<string>(Fluids.NA_FORMATE.id);
    const [sampleVolume, setSampleVolume] = useState<string>('5');
    const [dilutedSampleVolume, setDilutedSampleVolume] = useState<string>('50');
    const [concentrationTitrant, setConcentrationTitrant] = useState<string>('0.20');
    const [originalDensity, setOriginalDensity] = useState<string>('');
    const [originalPh, setOriginalPh] = useState<string>('');
    const [phAfterKOHAdjustment, setPhAfterKOHAdjustment] = useState<string>('');
    const [phAfterAcidAdjustment, setPhAfterAcidAdjustment] = useState<string>('');
    const [titrantVolume, setTitrantVolume] = useState<string>('');

    const [carbonateResult, setCarbonateResult] = useState<string>('');
    const [bicarbonateResult, setBicarbonateResult] = useState<string>('');

    const [originalPhError, setOriginalPhError] = useState<string>('');
    const [originalPhColor, setOriginalPhColor] = useState<PhColor>(undefined);

    const [increasedPhError, setIncreasedPhError] = useState<string>('');
    const [increasedPhColor, setIncreasedPhColor] = useState<PhColor>(undefined);

    const [reducedPhError, setReducedPhError] = useState<string>('');
    const [reducedPhColor, setReducedPhColor] = useState<PhColor>(undefined);
    //#endregion

    const _mustAcidBeAdded = mustAcidBeAdded(toNumber(originalPh));
    const _shouldAcidBeAdded = shouldAcidBeAdded(toNumber(originalPh));
    const _mustKohBeAdded = mustKohBeAdded(toNumber(originalPh));
    const _shouldKohBeAdded = shouldKohBeAdded(toNumber(originalPh));

    const allRequiredFieldsFilled = sampleVolume.length > 0 &&
    dilutedSampleVolume.length > 0 &&
    concentrationTitrant.length > 0 &&
    originalDensity.length > 0 &&
    pHFieldsAreValid(originalPh, phAfterKOHAdjustment, phAfterAcidAdjustment) &&
    titrantVolume.length > 0;

    // Logic to set the color of both ph adjusted fields
    let phAfterKOHAdjustmentColor:string = colors.notRequiredColor;
    if(originalPh.length > 0 && (_mustKohBeAdded || _shouldKohBeAdded)) {
        phAfterKOHAdjustmentColor = increasedPhColor as string;
        // if(increasedPhError.length > 0) {
        //     phAfterKOHAdjustmentColor = increasedPhColor as string;
        // } else {
        //     phAfterKOHAdjustmentColor = '';
        // }
    }

    let phAfterAcidAdjustmentColor:string = colors.notRequiredColor;
    if(originalPh.length > 0 && (_mustAcidBeAdded || _shouldAcidBeAdded)) {
        phAfterAcidAdjustmentColor = reducedPhColor as string;
        // if(reducedPhError.length > 0) {
        //     phAfterAcidAdjustmentColor = reducedPhColor as string;
        // } else {
        //     phAfterAcidAdjustmentColor = '';
        // }
    }

    //let phAfterAcidAdjustmentColor = (originalPh.length > 0 && _mustAcidBeAdded) ? '' : colors.notRequiredColor;

    //#region Triggers props methods when some fields changes
    useEffect(() => {
        if(props.onPhAfterKOHAdjustmentDisabledChanged !== undefined)
            props.onPhAfterKOHAdjustmentDisabledChanged(phAfterKOHAdjustmentDisabled);
    }, [props, phAfterKOHAdjustmentDisabled]);

    useEffect(() => {
        if(props.onPhAfterAcidAdjustmentDisabledChanged !== undefined)
            props.onPhAfterAcidAdjustmentDisabledChanged(phAfterAcidAdjustmentDisabled);
    }, [props, phAfterAcidAdjustmentDisabled]);

    useEffect(() => {
        if(props.onSampleDescriptionChanged !== undefined)
            props.onSampleDescriptionChanged(sampleDescription);
    }, [props, sampleDescription]);

    useEffect(() => {
        if(props.onOriginalPhErrorChanged !== undefined)
            props.onOriginalPhErrorChanged(originalPhError);
    }, [props, originalPhError]);

    useEffect(() => {
        if(props.onOriginalPhColorChanged !== undefined)
            props.onOriginalPhColorChanged(originalPhColor);
    }, [props, originalPhColor]);

    useEffect(() => {
        if(props.onIncreasedPhErrorChanged !== undefined)
            props.onIncreasedPhErrorChanged(increasedPhError);
    }, [props, increasedPhError]);

    useEffect(() => {
        if(props.onReducedPhErrorChanged !== undefined)
            props.onReducedPhErrorChanged(reducedPhError);
    }, [props, reducedPhError])

    useEffect(() => {
        if(props.onIncreasedPhColorChanged !== undefined)
            props.onIncreasedPhColorChanged(increasedPhColor);
    }, [props, increasedPhColor]);

    useEffect(() => {
        if(props.onReducedPhColorChanged !== undefined)
            props.onReducedPhColorChanged(reducedPhColor);
    }, [props, reducedPhColor])
    //#endregion

    //#region fields change handlers
    const onSampleDescriptionChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSampleDescription(event.target.value);
    }

    const onBlendTypeChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setBlendType(event.target.value);
    }

    const onSampleVolumeChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSampleVolume(event.target.value);
    }

    const onDilutedSampleVolumeChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setDilutedSampleVolume(event.target.value);
    }

    const onConcentrationTitrantChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setConcentrationTitrant(event.target.value);
    }

    const onOriginalDensityChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setOriginalDensity(event.target.value);
    }

    const onOriginalPhBlur = (event:React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        if(originalPh.length > 0)
            setOriginalPh(previous => toNumber(previous).toFixed(2));
    }

    const onPhAfterAcidAdjustmentBlur = (event:React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        if(phAfterAcidAdjustment.length > 0)
            setPhAfterAcidAdjustment(previous => toNumber(previous).toFixed(2));
    }

    const onPhAfterKOHAdjustmentBlur = (event:React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        if(phAfterKOHAdjustment.length > 0)
            setPhAfterKOHAdjustment(previous => toNumber(previous).toFixed(2));
    }

    const onTitrantVolumeChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setTitrantVolume(event.target.value);
    }
    //#endregion

    //#region pH validations
    const validateOriginalPh = useCallback((originalPh:string) => {
        if(originalPh.length === 0) {
            setOriginalPhError('');
            setPhAfterKOHAdjustmentDisabled(true);
            setPhAfterAcidAdjustmentDisabled(true);
            setOriginalPhColor(undefined);
            return;
        }

        const originalPhNumber = toNumber(originalPh);
        setIncreasedPhError('');
        setReducedPhError('');
        setReducedPhColor(undefined);
        setIncreasedPhColor(undefined);
        if(mustKohBeAdded(originalPhNumber)) {
            setOriginalPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setPhAfterKOHAdjustmentDisabled(false);
            setPhAfterAcidAdjustmentDisabled(true);
            setPhAfterAcidAdjustment('');
            setOriginalPhColor(colors.errorColor);
        } else if(shouldKohBeAdded(originalPhNumber)) {
            setOriginalPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setPhAfterKOHAdjustmentDisabled(false);
            setPhAfterAcidAdjustmentDisabled(true);
            setPhAfterAcidAdjustment('');
            setOriginalPhColor(colors.warningColor);
        } else if(mustAcidBeAdded(originalPhNumber)) {
            setOriginalPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setPhAfterAcidAdjustmentDisabled(false);
            setPhAfterKOHAdjustmentDisabled(true);
            setPhAfterKOHAdjustment('');
            setOriginalPhColor(colors.errorColor);
        } else if(shouldAcidBeAdded(originalPhNumber)) {
            setOriginalPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setPhAfterAcidAdjustmentDisabled(false);
            setPhAfterKOHAdjustmentDisabled(true);
            setPhAfterKOHAdjustment('');
            setOriginalPhColor(colors.warningColor);
        } else {
            setOriginalPhError('');
            setPhAfterAcidAdjustmentDisabled(true);
            setPhAfterKOHAdjustmentDisabled(true);
            setOriginalPhColor(undefined);
            setPhAfterAcidAdjustment('');
            setPhAfterKOHAdjustment('');
            setIncreasedPhColor(undefined);
            setIncreasedPhError('');
            setReducedPhColor(undefined);
            setReducedPhError('');
        }
    }, [colors.errorColor, colors.warningColor]);

    const onOriginalPhChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setOriginalPh(event.target.value);

        validateOriginalPh(event.target.value);
    }

    useEffect(() => {
        if(phAfterAcidAdjustment.length === 0 && phAfterKOHAdjustment.length === 0) {
            validateOriginalPh(originalPh)
        }
    }, [validateOriginalPh, originalPh, phAfterAcidAdjustment, phAfterKOHAdjustment])

    const onPhAfterKOHAdjustmentChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setPhAfterKOHAdjustment(event.target.value);

        if(event.target.value.length > 0) {
            setOriginalPhError('');
        } else {
            setIncreasedPhError('');
            setIncreasedPhColor(undefined);
            return;
        }

        const _phAfterKOHAdjustment = toNumber(event.target.value);
        if(mustKohBeAdded(_phAfterKOHAdjustment)) {
            //setIncreasedPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setIncreasedPhError('');
            setIncreasedPhColor(colors.errorColor);
        } else if(shouldKohBeAdded(_phAfterKOHAdjustment)) {
            //setIncreasedPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setIncreasedPhError('');
            setIncreasedPhColor(colors.warningColor);
        } else if(mustAcidBeAdded(_phAfterKOHAdjustment)) {
            //setIncreasedPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setIncreasedPhError('');
            setIncreasedPhColor(colors.errorColor);
        } else if(shouldAcidBeAdded(_phAfterKOHAdjustment)) {
            //setIncreasedPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setIncreasedPhError('');
            setIncreasedPhColor(colors.warningColor);
        } else {
            setIncreasedPhError('');
            setIncreasedPhColor(undefined);
        }
    }

    const onPhAfterAcidAdjustmentChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setPhAfterAcidAdjustment(event.target.value);

        if(event.target.value.length > 0) {
            setOriginalPhError('');
        } else {
            setReducedPhError('');
            setReducedPhColor(undefined);
            return;
        }

        const _phAfterAcidAdjustment = toNumber(event.target.value);
        if(mustKohBeAdded(_phAfterAcidAdjustment)) {
            setReducedPhError('');
            //setReducedPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setReducedPhColor(colors.errorColor);
        } else if(shouldKohBeAdded(_phAfterAcidAdjustment)) {
            setReducedPhError('');
            //setReducedPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setReducedPhColor(colors.warningColor);
        } else if(mustAcidBeAdded(_phAfterAcidAdjustment)) {
            setReducedPhError('');
            //setReducedPhError(PH_ADJUSTMENT_REQUIRED_MESSAGE);
            setReducedPhColor(colors.errorColor);
        } else if(shouldAcidBeAdded(_phAfterAcidAdjustment)) {
            setReducedPhError('');
            //setReducedPhError(PH_ADJUSTMENT_RECOMMENDED_MESSAGE);
            setReducedPhColor(colors.warningColor);
        } else {
            setReducedPhError('');
            setReducedPhColor(undefined);
        }
    }
    //#endregion

    const doCalculation = useCallback(() => {
        let carbonateBufferResult = '';
        let bicarbonateBufferResult = '';

        if(allRequiredFieldsFilled) {
            let adjustedPh:number | null = null;
            if(!phAfterKOHAdjustmentDisabled && phAfterKOHAdjustment.length > 0)
                adjustedPh = toNumber(phAfterKOHAdjustment);
            if(!phAfterAcidAdjustmentDisabled && phAfterAcidAdjustment.length > 0)
                adjustedPh = toNumber(phAfterAcidAdjustment);

            const result = calculateBuffer(
                toNumber(titrantVolume),
                toNumber(sampleVolume),
                toNumber(concentrationTitrant),
                toNumber(originalPh),
                adjustedPh,
                toNumber(originalDensity),
                {id: blendType } as Fluid,
                bufferConcentrationUnit as BufferConcentrationUnit,
                densityUnit as DensityUnit,
                bufferType as BufferType
            );

            switch(bufferConcentrationUnit) {
                case BufferConcentrationUnits.molPerLitre:
                    carbonateBufferResult = result.carbonateBuffer.toFixed(3);
                    bicarbonateBufferResult = result.bicarbonateBuffer.toFixed(3);
                    break;
                case BufferConcentrationUnits.kiloPerCubicMeter:
                    carbonateBufferResult = result.carbonateBuffer.toFixed(1);
                    bicarbonateBufferResult = result.bicarbonateBuffer.toFixed(1);
                    break;
                case BufferConcentrationUnits.poundsPerBarrel:
                    carbonateBufferResult = result.carbonateBuffer.toFixed(1);
                    bicarbonateBufferResult = result.bicarbonateBuffer.toFixed(1);
                    break;
                default:
                    carbonateBufferResult = `${result.carbonateBuffer}`;
                    bicarbonateBufferResult = `${result.bicarbonateBuffer}`;
                    break;
            }

            setCarbonateResult(carbonateBufferResult);
            setBicarbonateResult(bicarbonateBufferResult);
        } else {
            setCarbonateResult('');
            setBicarbonateResult('');
        }

        // Update row
        const newRow = instantiateNewRowWithData(props.data.id,
            props.data.createdAt,
            sampleDescription,
            blendType,
            sampleVolume,
            dilutedSampleVolume,
            concentrationTitrant,
            originalDensity,
            originalPh,
            phAfterKOHAdjustment,
            phAfterAcidAdjustment,
            titrantVolume,
            originalPhError,
            originalPhColor,
            phAfterAcidAdjustmentDisabled,
            phAfterKOHAdjustmentDisabled,
            carbonateBufferResult,
            bicarbonateBufferResult
        );

        let allRows = findAllRowsOnLocalStorage();
        allRows = allRows.filter(r => r.id !== props.data.id);
        allRows.push(newRow);
        updateRowsOnLocalStorage(allRows);
    }, [
        props.data.id,
        props.data.createdAt,
        sampleDescription,
        dilutedSampleVolume,
        sampleVolume,
        concentrationTitrant,
        originalDensity,
        originalPh,
        phAfterKOHAdjustment,
        phAfterAcidAdjustment,
        titrantVolume,
        bufferType,
        bufferConcentrationUnit,
        densityUnit,
        phAfterAcidAdjustmentDisabled,
        phAfterKOHAdjustmentDisabled,
        blendType,
        allRequiredFieldsFilled,
        originalPhColor,
        originalPhError
    ]);

    /**
     * When one of the significant row fields changes recalculates the result
     * and update rows.
     */
    useEffect(() => {
        doCalculation();
    }, [
        doCalculation,
        sampleDescription,
        sampleVolume,
        dilutedSampleVolume,
        concentrationTitrant,
        blendType,
        originalDensity,
        originalPh,
        phAfterKOHAdjustment,
        phAfterAcidAdjustment,
        titrantVolume,
        bufferType,
        bufferConcentrationUnit,
        densityUnit
    ]);

    /**
     * When the component mounts, get the data from props and set the state.
     * When the state is seted, the useEffect above will run and calculate the result.
     */
    useEffect(() => {
        setSampleDescription(props.data.sampleDescription);
        setBlendType(props.data.blendType);
        setSampleVolume(props.data.sampleVolume);
        setDilutedSampleVolume(props.data.dilutedSampleVolume);
        setConcentrationTitrant(props.data.concentrationTitrant);
        setOriginalDensity(props.data.originalDensity);
        setOriginalPh(props.data.originalPh);
        setPhAfterKOHAdjustment(props.data.phAfterKOHAdjustment);
        setPhAfterAcidAdjustment(props.data.phAfterAcidAdjustment);
        setTitrantVolume(props.data.titrantVolume);
        setOriginalPhError(props.data.originalPhError);
        setOriginalPhColor(props.data.originalPhColor);
        setPhAfterAcidAdjustmentDisabled(props.data.phAfterAcidAdjustmentDisabled);
        setPhAfterKOHAdjustmentDisabled(props.data.phAfterKOHAdjustmentDisabled);
        setCarbonateResult(props.data.carbonateResult);
        setBicarbonateResult(props.data.bicarbonateResult);
    }, [
        props.data.sampleDescription,
        props.data.blendType,
        props.data.sampleVolume,
        props.data.dilutedSampleVolume,
        props.data.concentrationTitrant,
        props.data.originalDensity,
        props.data.originalPh,
        props.data.phAfterKOHAdjustment,
        props.data.phAfterAcidAdjustment,
        props.data.titrantVolume,
        props.data.originalPhError,
        props.data.originalPhColor,
        props.data.phAfterAcidAdjustmentDisabled,
        props.data.phAfterKOHAdjustmentDisabled,
        props.data.carbonateResult,
        props.data.bicarbonateResult
    ]);

    let DensityUm = DefaultUm;
    switch(densityUnit) {
        case DensityUnits.gcm3:
            DensityUm = Gcm3;
            break;
        case DensityUnits.lbgal:
            DensityUm = Lbgal;
            break;
        case DensityUnits.pptf:
            DensityUm = Pptf;
            break;
    }

    const CarboAbbrev = (bufferType === BufferTypes.SODIUM) ? SodiumCarbonate : PotassiumCarbonate;
    const BicarboAbbrev = (bufferType === BufferTypes.SODIUM) ? SodiumBicarbonate : PotassiumBicarbonate;

    let Um = DefaultUm;
    switch(bufferConcentrationUnit) {
        case BufferConcentrationUnits.kiloPerCubicMeter:
            Um = Kgm3;
            break;
        case BufferConcentrationUnits.molPerLitre:
            Um = Moll;
            break;
        case BufferConcentrationUnits.poundsPerBarrel:
            Um = Lbbbl;
            break;
    }

    return (
        <>
            <FieldContainer label='Sample description' style={{width: '30vh'}}>
                <TextField
                    size='small'
                    value={sampleDescription}
                    onChange={onSampleDescriptionChange}
                    sx={{width: '30vh'}}
                    name='sample_description'
                    id={`sample_description_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label='Formate brine/blend type' style={{width: '12vh'}}>
                <Select
                    value={blendType}
                    onChange={onBlendTypeChange}
                    size='small'
                    sx={{width: '12vh'}}
                    name={`blend_type_${props.data.id}`}
                    id={`blend_type_${props.data.id}`}
                >
                    <MenuItem value={Fluids.NA_FORMATE.id} id={`blend_type_${props.data.id}_na_option`}>{Fluids.NA_FORMATE.abbreviation}</MenuItem>
                    <MenuItem value={Fluids.K_FORMATE.id} id={`blend_type_${props.data.id}_k_option`}>{Fluids.K_FORMATE.abbreviation}</MenuItem>
                    <MenuItem value={Fluids.CS_FORMATE.id} id={`blend_type_${props.data.id}_cs_option`}>{Fluids.CS_FORMATE.abbreviation}</MenuItem>
                    <MenuItem value={Fluids.NA_K_FORMATE.id} id={`blend_type_${props.data.id}_na_k_option`}>{Fluids.NA_K_FORMATE.abbreviation}</MenuItem>
                    <MenuItem value={Fluids.CS_K_FORMATE.id} id={`blend_type_${props.data.id}_cs_k_option`}>{Fluids.CS_K_FORMATE.abbreviation}</MenuItem>
                </Select>
            </FieldContainer>

            <FieldContainer label='Sample volume [mL]'>
                <TextField
                    size='small'
                    value={sampleVolume}
                    onChange={onSampleVolumeChange}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{ 'input': {textAlign: 'center'}}}
                    name='sample_volume'
                    id={`sample_volume_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label='Diluted sample volume [mL]'>
                <TextField
                    size='small'
                    value={dilutedSampleVolume}
                    onChange={onDilutedSampleVolumeChange}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{ 'input': {textAlign: 'center'}}}
                    name='diluted_sample_volume'
                    id={`diluted_sample_volume_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label='Concentration titrant [N]'>
                <TextField
                    size='small'
                    value={concentrationTitrant}
                    onChange={onConcentrationTitrantChange}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{ 'input': {textAlign: 'center'}}}
                    name='concentration_titrant'
                    id={`concentration_titrant_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label={<>Density [<DensityUm />]</>} style={{width: '9vh'}}>
                <TextField
                    size='small'
                    value={originalDensity}
                    onChange={onOriginalDensityChange}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{ 'input': {textAlign: 'center'}}}
                    name='density'
                    id={`density_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label='Original pH' id={`original_ph_${props.data.id}_td`}>
                <TextField
                    size='small'
                    value={originalPh}
                    onChange={onOriginalPhChange}
                    onBlur={onOriginalPhBlur}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{
                        backgroundColor: originalPhColor,
                        'input': {textAlign: 'center'}
                    }}
                    name='original_ph'
                    id={`original_ph_${props.data.id}`}
                />
            </FieldContainer>
            <FieldContainer label='Increased pH' id={`increased_ph_${props.data.id}_td`}>
                <TextField
                    disabled={phAfterKOHAdjustmentDisabled}
                    size='small'
                    value={phAfterKOHAdjustment}
                    onChange={onPhAfterKOHAdjustmentChange}
                    onBlur={onPhAfterKOHAdjustmentBlur}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{
                        backgroundColor:  phAfterKOHAdjustmentColor, //(phAfterKOHAdjustmentDisabled) ? phAfterKOHAdjustmentColor : undefined,
                        'input': {textAlign: 'center'}
                    }}
                    name='increased_ph'
                    id={`increased_ph_${props.data.id}`}
                />
            </FieldContainer>
            <FieldContainer label='Reduced pH' id={`reduced_ph_${props.data.id}_td`}>
                <TextField
                    disabled={phAfterAcidAdjustmentDisabled}
                    size='small'
                    value={phAfterAcidAdjustment}
                    onChange={onPhAfterAcidAdjustmentChange}
                    onBlur={onPhAfterAcidAdjustmentBlur}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{
                        backgroundColor: phAfterAcidAdjustmentColor, //(phAfterAcidAdjustmentDisabled) ? phAfterAcidAdjustmentColor : undefined,
                        'input': {textAlign: 'center'}
                    }}
                    name='reduced_ph'
                    id={`reduced_ph_${props.data.id}`}
                />
            </FieldContainer>
            <FieldContainer label='Titrant volume at pH = 8.2 [mL]'>
                <TextField
                    size='small'
                    value={titrantVolume}
                    onChange={onTitrantVolumeChange}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{'input': {textAlign: 'center'}}}
                    name='titrant_volume'
                    id={`titrant_volume_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label={<><CarboAbbrev /> [<Um />]</>} style={{width: '10vh'}}>
                <TextField
                    size='small'
                    value={carbonateResult}
                    sx={{
                        backgroundColor: colors.resultColor,
                        'input': {textAlign: 'center'}
                    }}
                    name='result_carbonate'
                    id={`result_carbonate_${props.data.id}`}
                />
            </FieldContainer>

            <FieldContainer label={<><BicarboAbbrev /> [<Um />]</>} style={{width: '10vh'}}>
                <TextField
                    size='small'
                    value={bicarbonateResult}
                    sx={{
                        backgroundColor: colors.resultColor,
                        'input': {textAlign: 'center'}
                    }}
                    name='result_bicarbonate'
                    id={`result_bicarbonate_${props.data.id}`}
                />
            </FieldContainer>
        </>
    );
}