import { Row, sortRows } from "../model/Row";

export function findAllRowsOnLocalStorage() {
    const storagedRowsRaw = localStorage.getItem('ROWS');
    if(storagedRowsRaw !== null) {
        const allRows = JSON.parse(storagedRowsRaw) as Array<Row>;
        sortRows(allRows);
        return allRows;
    } else {
        return [];
    }
}

export function updateRowsOnLocalStorage(rows: Array<Row>) {
    localStorage.removeItem('ROWS');
    localStorage.setItem('ROWS', JSON.stringify(rows));
}