import React, { useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import DataList from '../DataList';
import { StateContext } from '../../providers/StateProvider';
import { instantiateNewRow } from '../../model/Row';
import { ThemeContext } from '../../providers/ThemeProvider';

export default function DataListWrapper() {
    const { setRows } = useContext(StateContext);
    const { colors } = useContext(ThemeContext);

    const handleOnFabClick = () => {
        setRows(prev => [...prev, instantiateNewRow(prev)]);
    }

    const handleOnPrintFabClick = () => {
        const url = window.origin + '/print';
        window.open(url, '_blank');
    }

    return (
        <>
            <Accordion defaultExpanded sx={{margin: 0, minHeight: 0}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                >
                    <Typography variant="h5">Samples</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DataList />
                </AccordionDetails>
            </Accordion>
            <Fab sx={{
                    position: 'fixed',
                    bottom: 15,
                    right: 15,
                    backgroundColor: colors.primaryColor
                }}
                color='success'
                onClick={handleOnFabClick}
            >
                <AddIcon />
            </Fab>

            <Fab sx={{
                    position: 'fixed',
                    bottom: 15,
                    left: 15,
                    backgroundColor: '#E0E0E0',
                    color: 'rgba(0, 0, 0, 0.87)'
                }}
                onClick={handleOnPrintFabClick}
            >
                <PrintIcon />
            </Fab>
        </>
    );
}