import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { StateContext } from '../../providers/StateProvider';
import { BufferConcentrationUnits, BufferTypes } from '@fastsheep/buffercalc-core';
import { useSettings } from '../../hooks/useSettings';

export default function CalculationSettings() {
    let {
        bufferType,
        setBufferType,
        bufferConcentrationUnit,
        setBufferConcentrationUnit,
        densityUnit,
        setDensityUnit,
    } = useContext(StateContext);

    const handleOnBufferTypeChange = (event: SelectChangeEvent) => {
        setBufferType(event.target.value);
    }

    const handleOnBufferConcentrationUnitChange = (event: SelectChangeEvent) => {
        setBufferConcentrationUnit(event.target.value);
    }

    const handleOnDensityUnitChange = (event: SelectChangeEvent) => {
        setDensityUnit(event.target.value);
    }

    useSettings();

    return (
        <Accordion defaultExpanded sx={{margin: 0, minHeight: 0}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
            >
                <Typography variant="h5">Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={3}>
                    <FormControl sx={{width: '150px'}}>
                        <InputLabel id='buffer_type_label'>Buffer type</InputLabel>
                        <Select
                            size='small'
                            labelId='buffer_type_label'
                            label='buffer_type'
                            value={bufferType}
                            onChange={handleOnBufferTypeChange}
                            name='buffer_type'
                            id='buffer_type'
                        >
                            <MenuItem value={BufferTypes.POTASSIUM} id='buffer_type_potassium_option'>K</MenuItem>
                            <MenuItem value={BufferTypes.SODIUM} id='buffer_type_sodium_option'>Na</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '200px', marginLeft: '10px'}}>
                        <InputLabel id='buffer_concentration_unit_label'>Buffer concentration unit</InputLabel>
                        <Select
                            size='small'
                            labelId='buffer_concentration_unit_label'
                            label='buffer_concentration_unit'
                            value={bufferConcentrationUnit}
                            onChange={handleOnBufferConcentrationUnitChange}
                            name='buffer_concentration_unit'
                            id='buffer_concentration_unit'
                        >
                            <MenuItem value={BufferConcentrationUnits.molPerLitre}>mol/l</MenuItem>
                            <MenuItem value={BufferConcentrationUnits.kiloPerCubicMeter}>kg/m³</MenuItem>
                            <MenuItem value={BufferConcentrationUnits.poundsPerBarrel}>lb/bbl</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '150px', marginLeft: '10px'}}>
                        <InputLabel id='density_unit_label'>Density unit</InputLabel>
                        <Select
                            size='small'
                            labelId='density_unit_label'
                            label='density_unit'
                            value={densityUnit}
                            onChange={handleOnDensityUnitChange}
                            name='density_unit'
                            id='density_unit'
                        >
                            <MenuItem value='g/cm3'>g/cm³</MenuItem>
                            <MenuItem value='lb/gal'>lb/gal</MenuItem>
                            <MenuItem value='pptf'>pptf</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}