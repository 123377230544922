import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ISO_LINK } from '../../constants/links';

const Li = (props:any) => {
    return <Box sx={{
        'li': {
            fontSize: 'inherit',
            position: 'relative'
        },
        'li:before': {
            position: 'absolute',
            content: '"•"',
            color: '#808285',
            paddingRight: '10px',
            fontSize: '83px',
            fontWeight: 300,
            top: isMobile ? '-25%' : '-180%',
            left: isMobile ? '-10%' : '-1.8%'
    }}}><li>{props.children}</li></Box>
}

export default function Instructions() {
    return (
        <div>
            <Accordion sx={{margin: 0, minHeight: 0}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                >
                    <div style={{display: 'flex', flexFlow: 'column nowrap'}}>
                        <Typography variant="h5">Instructions</Typography>
                        {
                            isMobile &&
                            <Typography
                                sx={{
                                    color: '#164194',
                                    fontSize: '0.6em'
                                }}
                                onClick={() => window.open(ISO_LINK, '_blank')}
                            >
                                Calculations are in accordance with ISO 13503-3:2022
                            </Typography>
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>1. Measure an exact volume of the brine or drilling fluid filtrate into a 50 mL conical flask (5 mL recommended). Input the volume.</Typography>
                    <Typography>2. Dilute the sample with nine parts deionised water (no exact measurement required).</Typography>
                    <Typography><b>3. Measure pH very accurately with a calibrated glass electrode</b> prior to titration. (Note: the fluid is already diluted 1:10 and does not need to be diluted again).</Typography>
                    <Typography>4. If pH is not in the 9.5—11.0 (preferably 10.0—10.5) range adjust as follows:</Typography>
                    <ul style={{listStyle: 'none', margin: 0}}>
                    <Li><Typography>If pH is lower than the lower limit, add a strong base (e.g., KOH or NaOH) until the pH is in the correct range.</Typography></Li>
                    <Li><Typography>If pH is higher than the higher limit, add acid (e.g., HCl) until the pH is in the correct range.</Typography></Li>
                    </ul>
                    <Typography>5. Add five drops of phenolpthalein indicator (optional).</Typography>
                    <Typography>6. Insert magnetic stirrer and mix thoroughly.</Typography>
                    <Typography>7. Input the concentration of the acid that will be used for the titration. 0.2 N acid is recommended.</Typography>
                    <Typography>8. Titrate dropwise with acid from a 10 mL glass pipette until pH reaches 8.2 (or if phenolpthalein is used, titrate until the pink colour disappears).</Typography>
                    <Typography>9. Input the quantity of acid used below.</Typography>
                    <Typography>10. Measure the final pH and confirm that it is approximately 8.2.</Typography>
                    <div style={{marginTop: '15px'}}></div>
                    <Typography><b>The calculations only work when the (adjusted) pH is in the correct range and all required input (white cells) is entered.</b></Typography>
                    <Typography><b>NOTE: pH must be measured accurately with a calibrated glass electrode (1:10 dilution).</b></Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}