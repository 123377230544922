import { BufferConcentrationUnits, BufferTypes, DensityUnits } from "@fastsheep/buffercalc-core";
import { useContext } from "react";
import { StateContext } from "../../../providers/StateProvider";
import { DefaultUm, Gcm3, Kgm3, Lbbbl, Lbgal, Moll, PotassiumBicarbonate, PotassiumCarbonate, Pptf, SodiumBicarbonate, SodiumCarbonate } from "../../Units";

interface DataGridHeaderProps {
    variant: 'printpage' | 'default'
}
export function DataGridHeader(props:DataGridHeaderProps) {
    const {
        bufferType,
        bufferConcentrationUnit,
        densityUnit
    } = useContext(StateContext);

    const CarboAbbrev = (bufferType === BufferTypes.SODIUM) ? SodiumCarbonate : PotassiumCarbonate;
    const BicarboAbbrev = (bufferType === BufferTypes.SODIUM) ? SodiumBicarbonate : PotassiumBicarbonate;

    let Um = DefaultUm;
    switch(bufferConcentrationUnit) {
        case BufferConcentrationUnits.kiloPerCubicMeter:
            Um = Kgm3;
            break;
        case BufferConcentrationUnits.molPerLitre:
            Um = Moll;
            break;
        case BufferConcentrationUnits.poundsPerBarrel:
            Um = Lbbbl;
            break;
    }

    let DensityUm = DefaultUm;
    switch(densityUnit) {
        case DensityUnits.gcm3:
            DensityUm = Gcm3;
            break;
        case DensityUnits.lbgal:
            DensityUm = Lbgal;
            break;
        case DensityUnits.pptf:
            DensityUm = Pptf;
            break;
    }

    return (
        <tr style={{
            backgroundColor: (props.variant === 'default') ? '#E0E0E0' : '#878787',
            color: (props.variant === 'default') ? 'inherit' : 'white'
        }}>
            <th>{`Sample description`}</th>
            <th>Formate brine/blend type</th>
            <th>Sample volume<br />[mL]</th>
            <th>Diluted sample volume [mL]</th>
            <th>Concentration titrant<br />[N]</th>
            <th>Density<br />[<DensityUm />]</th>
            <th>{`Original pH`}</th>
            <th>{`Increased pH`}</th>
            <th>{`Reduced pH`}</th>
            <th>Titrant volume at<br />pH = 8.2 [mL]</th>
            <th><CarboAbbrev /><br />[<Um />]</th>
            <th><BicarboAbbrev /><br />[<Um />]</th>
            {(props.variant === 'default') && <th></th>}
        </tr>
    );
}