import { BufferConcentrationUnits, BufferTypes, DensityUnits } from '@fastsheep/buffercalc-core';
import React, { createContext, useState } from 'react';
import { Row } from '../model/Row';
import { Settings } from '../model/Settings';

const defaultBufferType = BufferTypes.POTASSIUM;
const defaultBufferConcentrationUnit = BufferConcentrationUnits.poundsPerBarrel;
const defaultDensityUnit = DensityUnits.gcm3;

interface State {
    bufferType: string,
    bufferConcentrationUnit: string,
    densityUnit: string,
    setBufferType: React.Dispatch<React.SetStateAction<string>>,
    setBufferConcentrationUnit: React.Dispatch<React.SetStateAction<string>>,
    setDensityUnit: React.Dispatch<React.SetStateAction<string>>,
    rows: Array<Row>,
    setRows: React.Dispatch<React.SetStateAction<Array<Row>>>,
    settings: Settings,
    setSettings: React.Dispatch<React.SetStateAction<Settings>>,
}

const defaultSettings:Settings = {
    bufferType: defaultBufferType,
    bufferConcentrationUnit: defaultBufferConcentrationUnit,
    densityUnit: defaultDensityUnit
}

const defaultState:State = {
    bufferType: defaultBufferType,
    bufferConcentrationUnit: defaultBufferConcentrationUnit,
    densityUnit: defaultDensityUnit,
    setBufferType: () => {},
    setBufferConcentrationUnit: () => {},
    setDensityUnit: () => {},
    rows: [],
    setRows: () => {},
    settings: defaultSettings,
    setSettings: () => {}
}

export const StateContext = createContext<State>(defaultState);

interface StateProviderProps {
    children:React.ReactNode
}

export default function StateProvider({children}:StateProviderProps) {
    let [bufferType, setBufferType] = useState<string>(defaultBufferType);
    let [bufferConcentrationUnit, setBufferConcentrationUnit] = useState<string>(defaultBufferConcentrationUnit);
    let [densityUnit, setDensityUnit] = useState<string>(defaultDensityUnit);
    let [rows, setRows] = useState<Array<Row>>([]);
    let [settings, setSettings] = useState<Settings>(defaultSettings);

    const state:State = {
        bufferType, setBufferType,
        bufferConcentrationUnit, setBufferConcentrationUnit,
        densityUnit, setDensityUnit,
        rows, setRows,
        settings, setSettings
    }

    return (
        <StateContext.Provider value={state}>
            {children}
        </StateContext.Provider>
    );
}