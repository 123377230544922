import { Fluids } from "@fastsheep/buffercalc-core";
import { getNextId } from "../utils/SequenceUtils";
import { PhColor } from "./PhColor";

export function sortRows(rows: Array<Row>): void {
    rows.sort((a:Row, b:Row) => a.createdAt - b.createdAt);
}

export interface Row {
    id:number,
    createdAt: number,
    sampleDescription: string,
    blendType: string,
    sampleVolume: string,
    dilutedSampleVolume: string,
    concentrationTitrant: string,
    originalDensity: string,
    originalPh: string,
    phAfterKOHAdjustment: string,
    phAfterAcidAdjustment: string,
    titrantVolume: string,
    originalPhError: string,
    originalPhColor: PhColor,
    phAfterAcidAdjustmentDisabled: boolean,
    phAfterKOHAdjustmentDisabled: boolean,
    carbonateResult: string,
    bicarbonateResult: string
}

export const instantiateNewRow = (rows:Array<Row>):Row => {
    sortRows(rows);
    const lastRow = rows.at(-1);

    let blendType = (lastRow === undefined) ? Fluids.NA_FORMATE.id : lastRow.blendType;
    let sampleVolume = (lastRow === undefined) ? '5' : lastRow.sampleVolume;
    let dilutedSampleVolume = (lastRow === undefined) ? '50' : lastRow.dilutedSampleVolume;
    let concentrationTitrant = (lastRow === undefined) ? '0.20' : lastRow.concentrationTitrant;

    const newRow:Row = {
        id: getNextId(),
        createdAt: Date.now(),
        sampleDescription: `Sample ${rows.length + 1}`,
        blendType: blendType,
        sampleVolume: sampleVolume,
        dilutedSampleVolume: dilutedSampleVolume,
        concentrationTitrant: concentrationTitrant,
        originalDensity: '',
        originalPh: '',
        phAfterKOHAdjustment: '',
        phAfterAcidAdjustment: '',
        titrantVolume: '',
        originalPhError: '',
        originalPhColor: undefined,
        phAfterAcidAdjustmentDisabled: true,
        phAfterKOHAdjustmentDisabled: true,
        carbonateResult: '',
        bicarbonateResult: ''
    }

    return newRow;
};

export const instantiateNewRowWithData = (id:number,
    createdAt: number,
    sampleDescription: string,
    blendType: string,
    sampleVolume: string,
    dilutedSampleVolume: string,
    concentrationTitrant: string,
    originalDensity: string,
    originalPh: string,
    phAfterKOHAdjustment: string,
    phAfterAcidAdjustment: string,
    titrantVolume: string,
    originalPhError: string,
    originalPhColor: PhColor,
    phAfterAcidAdjustmentDisabled: boolean,
    phAfterKOHAdjustmentDisabled: boolean,
    carbonateResult: string,
    bicarbonateResult: string):Row => {
        return {
            id,
            createdAt,
            sampleDescription,
            blendType,
            sampleVolume,
            dilutedSampleVolume,
            concentrationTitrant,
            originalDensity,
            originalPh,
            phAfterKOHAdjustment,
            phAfterAcidAdjustment,
            titrantVolume,
            originalPhError,
            originalPhColor,
            phAfterAcidAdjustmentDisabled,
            phAfterKOHAdjustmentDisabled,
            carbonateResult,
            bicarbonateResult
        };
}