import React from 'react';
import { isMobile } from 'react-device-detect';
import Typography from '@mui/material/Typography';

interface FieldContainerProps {
    children: React.ReactNode,
    label?: React.ReactNode,
    style?: any,
    id?: string
}

export default function FieldContainer({children, label, style, id}:FieldContainerProps) {
    return (
        <>
            {
                isMobile
                ?
                <div>
                    {
                        (label !== undefined) &&
                        <Typography>{label}</Typography>
                    }
                    {children}
                </div>
                :
                <td style={style} id={id}>{children}</td>
            }
        </>
    );
}