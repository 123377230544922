import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Row, sortRows } from '../../../model/Row';
import Fields from '../../Fields';
import { StateContext } from '../../../providers/StateProvider';
import { PhColor } from '../../../model/PhColor';
import { findAllRowsOnLocalStorage, updateRowsOnLocalStorage } from '../../../services/RowService';

interface DataGridRowProps {
    data: Row
}

export default function DataGridRow({data}:DataGridRowProps) {
    const { setRows } = useContext(StateContext);
    const [originalPhError, setOriginalPhError] = useState<string>('');
    const [originalPhColor, setOriginalPhColor] = useState<PhColor>(undefined);

    const [increasedPhError, setIncreasedPhError] = useState<string>('');
    const [increasedPhColor, setIncreasedPhColor] = useState<PhColor>(undefined);

    const [reducedPhError, setReducedPhError] = useState<string>('');
    const [reducedPhColor, setReducedPhColor] = useState<PhColor>(undefined);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const onOriginalPhErrorChanged = (phError:string) => {
        setOriginalPhError(phError);
    }

    const handleOnDeleteButtonClick = (rowId:number) => {
        let allRows = findAllRowsOnLocalStorage();

        if(allRows.length === 1) {
            setSnackbarOpen(true);
            return;
        }

        allRows = allRows.filter(r => r.id !== rowId);

        sortRows(allRows);
        setRows(allRows);
        updateRowsOnLocalStorage(allRows);
    }

    const hasRowsBelow = (rowId: number) => {
        let allRows = findAllRowsOnLocalStorage();
        return allRows.filter(row => row.id > rowId).length > 0;
    }

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message="Sorry, you cannot delete all rows."
            />

            <tr>
                <Fields
                    data={data}
                    onOriginalPhErrorChanged={onOriginalPhErrorChanged}
                    onOriginalPhColorChanged={value => setOriginalPhColor(value)}
                    onIncreasedPhErrorChanged={value => setIncreasedPhError(value)}
                    onIncreasedPhColorChanged={value => setIncreasedPhColor(value)}
                    onReducedPhErrorChanged={value => setReducedPhError(value)}
                    onReducedPhColorChanged={value => setReducedPhColor(value)}
                />
                <td>
                    <IconButton
                        onClick={() => handleOnDeleteButtonClick(data.id)}
                        name='delete_row_button'
                        id={`delete_row_button_${data.id}`}
                    >
                        <DeleteIcon />
                    </IconButton>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td id={`original_ph_error_td_${data.id}`}>
                    <Typography variant='caption' sx={{color: originalPhColor}}>
                        {!hasRowsBelow(data.id) && originalPhError}
                    </Typography>
                </td>
                <td id={`increased_ph_error_td_${data.id}`}>
                    <Typography variant='caption' sx={{color: increasedPhColor}}>{increasedPhError}</Typography>
                </td>
                <td id={`reduced_ph_error_td_${data.id}`}>
                    <Typography variant='caption' sx={{color: reducedPhColor}}>{reducedPhError}</Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </>
    );
}
