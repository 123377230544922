import { useContext, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StateContext } from '../../providers/StateProvider';
import { Row } from '../../model/Row';
import EditSampleDialog from '../EditSampleDialog';
import { findAllRowsOnLocalStorage } from '../../services/RowService';

export default function DataList() {
    const { rows, setRows } = useContext(StateContext);
    const [selectedRow, setSelectedRow] = useState<Row | null>(null);
    const [editSampleDialogOpen, setEditSampleDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        setRows(findAllRowsOnLocalStorage());
    }, [setRows]);

    const handleOnDeleteButtonClick = (rowId:number) => {
        setRows(prev => {
            return prev.filter(r => r.id !== rowId);
        });
    }

    const handleOnEditButtonClick = (row:Row) => {
        setSelectedRow(row);
        setEditSampleDialogOpen(true);
    }

    const handleOnEditSampleDialogClose = () => {
        setRows(findAllRowsOnLocalStorage());
        setEditSampleDialogOpen(false);
    }

    return (
        <>
            <List>
                {
                    rows.map((row, index) =>
                        <ListItem key={index}>
                            <ListItemText primary={row.sampleDescription} />
                            <Stack direction='row'>
                                <IconButton onClick={() => handleOnEditButtonClick(row)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleOnDeleteButtonClick(row.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </ListItem>
                    )
                }
            </List>

            <EditSampleDialog
                row={selectedRow}
                open={editSampleDialogOpen}
                onClose={handleOnEditSampleDialogClose}
            />
        </>
    );
}