import { useContext, useEffect } from "react";
import { Settings } from "../model/Settings";
import { StateContext } from "../providers/StateProvider";

export function useSettings() {
    const {
        bufferType,
        bufferConcentrationUnit,
        densityUnit,
        setBufferType,
        setBufferConcentrationUnit,
        setDensityUnit
    } = useContext(StateContext);

    // Load the stored settings when the program starts.
    useEffect(() => {
        const settingsStoredRaw = localStorage.getItem('SETTINGS');
        if(settingsStoredRaw === null) {
            localStorage.setItem('SETTINGS', JSON.stringify({
                bufferType,
                bufferConcentrationUnit,
                densityUnit
            }));
        } else {
            const settingsStored = JSON.parse(settingsStoredRaw) as Settings;
            setBufferType(settingsStored.bufferType);
            setBufferConcentrationUnit(settingsStored.bufferConcentrationUnit);
            setDensityUnit(settingsStored.densityUnit);
        }
    }, [
        setBufferType,
        setBufferConcentrationUnit,
        setDensityUnit
    ]);

    // When a setting is changed, updated the local storage.
    useEffect(() => {
        localStorage.setItem('SETTINGS', JSON.stringify({
            bufferType,
            bufferConcentrationUnit,
            densityUnit
        }));
    }, [ bufferType, bufferConcentrationUnit, densityUnit]);
}