import Bubbles from '../Bubbles';
import { isMobile } from 'react-device-detect';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CalculationSettings from '../CalculationSettings';
import DataGridWrapper from '../DataGridWrapper';
import Instructions from '../Instructions';
import Legend from '../Legend';
import DataListWrapper from '../DataListWrapper';
import Banner from '../../assets/img/buffer_calc_banner.png'
import BannerMobile from '../../assets/img/1 Mobile BufferCalc Banner (1546px X 423px).png';
import { disableDebugMode, enableDebugMode } from '@fastsheep/buffercalc-core';
import { ISO_LINK } from '../../constants/links';

window.enableDebugMode = enableDebugMode;
window.disableDebugMode = disableDebugMode;

export default function BufferCalc() {
    return (
        <>
            <Box>
                {
                    isMobile
                    ?
                    <img alt='banner' src={BannerMobile} style={{width: '100%'}} />
                    :
                    <>
                        <img useMap='#banner_link' alt='banner' src={Banner} style={{width: '100%'}} />
                        <map name="banner_link">
                            <area shape="rect" coords="40,230,450,400" href={ISO_LINK} target='_blank' />
                        </map>
                    </>
                }
            </Box>
            <Grid container padding='25px' direction='column' spacing={3} wrap='nowrap'>
                <Grid item xs={12} sm={12} md={12} lg={12} id='grid-instructions-wrapper'>
                    <Instructions />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CalculationSettings />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Legend />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {isMobile ? <DataListWrapper /> : <DataGridWrapper />}
                </Grid>
            </Grid>

            { /*<Bubbles />*/ }
        </>
    );
}