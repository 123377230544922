import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeContext } from '../../providers/ThemeProvider';

export default function Legend() {
    const { colors } = useContext(ThemeContext);

    const chipStyle={
        span: {color: 'black', width: '60px', textAlign: 'center'},
        height: '18px',
        fontWeight: 'bold'
    }

    return (
        <>
            <Accordion defaultExpanded sx={{margin: 0, minHeight: 0}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='legend-content'
                    id='legend-header'
                >
                    <Typography variant='h5'>Legend</Typography>
                </AccordionSummary>
                <AccordionDetails id='legend-content'>
                    <Stack direction={(isMobile) ? 'column' : 'row'} spacing={(isMobile) ? 1 : 5}>
                        <Stack direction='column' spacing={1}>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Chip label='amber' sx={{...chipStyle, backgroundColor: colors.warningColor }} />
                                <Typography>It is <b>recommended</b> to adjust pH to 10.0—10.5</Typography>
                            </Stack>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Chip label='red' sx={{...chipStyle, backgroundColor: colors.errorColor }} />
                                <Typography>pH <b>must</b> be adjusted to 9.5—11.0 (preferably 10.0—10.5)</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='column' spacing={1}>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Chip label='grey' sx={{...chipStyle, backgroundColor: colors.notRequiredColor }} />
                                <Typography>Field not required</Typography>
                            </Stack>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Chip label='light blue' sx={{...chipStyle, backgroundColor: colors.resultColor }} />
                                <Typography>Calculated result</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    );
}