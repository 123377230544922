export const SodiumCarbonate = () => {
    return <span>Na<sub>2</sub>CO<sub>3</sub></span>
}

export const PotassiumCarbonate = () => {
    return <span>K<sub>2</sub>CO<sub>3</sub></span>
}

export const SodiumBicarbonate = () => {
    return <span>NaHCO<sub>3</sub></span>
}

export const PotassiumBicarbonate = () => {
    return <span>KHCO<sub>3</sub></span>
}

export const Moll = () => {
    return <span>mol/l</span>
}

export const Lbbbl = () => {
    return <span>lb/bbl</span>
}

export const Kgm3 = () => {
    return <span>kg/m³</span>
}

export const DefaultUm = () => {
    return <span></span>
}

export const Gcm3 = () => {
    return <span>g/cm³</span>
}

export const Lbgal = () => {
    return <span>lb/gal</span>
}

export const Pptf = () => {
    return <span>pptf</span>
}