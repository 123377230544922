import { useEffect, useState } from "react";
import { Fluids, getFluidAbbreviationByFluidId } from '@fastsheep/buffercalc-core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { Row } from "../../model/Row";
import { findAllRowsOnLocalStorage } from "../../services/RowService";

export default function Print() {
    const [rows, setRows] = useState<Array<Row>>([]);

    useEffect(() => {
        setRows(findAllRowsOnLocalStorage());
    }, [setRows]);

    const onPrintClick = () => {
        const printButton = document.getElementById('print_page_buttons_container');
        if(printButton !== null) {
            const previousDisplay = printButton?.style.display;
            printButton.style.display = 'none'
            window.print();
            printButton.style.display = previousDisplay;
        }
    }

    const onCalculatorClick = () => {
        const url = window.origin + '/';
        window.open(url, '_blank');
    }

    return(
        <Box
            padding={2}
            sx={{
                'table, th, td': {
                    border: '1px solid black',
                    padding: '8px',
                    margin: '0px'
                },
                'table': {
                    borderCollapse: 'collapse'
                }
            }}
        >
            <div style={{
                width: '297mm',
                padding: '3%'
            }}>
                <table>
                    <caption><Typography variant='h6' sx={{marginBottom: '10px'}}>Samples</Typography></caption>
                    <DataGridHeader variant="printpage" />
                    {
                        rows.map((row, idx) => {
                            return (
                                <tr key={idx} style={{backgroundColor: (idx % 2 === 0) ? 'white' : '#DADADA'}}>
                                    <td align="center"><Typography>{row.sampleDescription}</Typography></td>
                                    <td align="center"><Typography>{getFluidAbbreviationByFluidId(row.blendType as typeof Fluids.K_FORMATE.id)}</Typography></td>
                                    <td align="center"><Typography>{row.sampleVolume}</Typography></td>
                                    <td align="center"><Typography>{row.dilutedSampleVolume}</Typography></td>
                                    <td align="center"><Typography>{row.concentrationTitrant}</Typography></td>
                                    <td align="center"><Typography>{row.originalDensity}</Typography></td>
                                    <td align="center"><Typography>{row.originalPh}</Typography></td>
                                    <td align="center"><Typography>{row.phAfterKOHAdjustment}</Typography></td>
                                    <td align="center"><Typography>{row.phAfterAcidAdjustment}</Typography></td>
                                    <td align="center"><Typography>{row.titrantVolume}</Typography></td>
                                    <td align="center"><Typography>{row.carbonateResult}</Typography></td>
                                    <td align="center"><Typography>{row.bicarbonateResult}</Typography></td>
                                </tr>
                        )})
                    }
                </table>
            </div>

            <div
                id='print_page_buttons_container'
                style={{
                    display:'flex',
                    flexFlow: 'row-reverse',
                    justifyContent: 'flex-start',
                    width: '297mm',
                    marginLeft: '3%'
                }}
            >
                <Button
                    id='print_page_print_button'
                    variant='contained'
                    sx={{
                        backgroundColor: '#164194',
                        marginTop: 5,
                        width: 120
                    }}
                    onClick={onPrintClick}
                >
                    Print
                </Button>

                <Button
                    id='print_page_calculator_button'
                    variant='contained'
                    sx={{
                        backgroundColor: '#E0E0E0',
                        color: 'rgba(0, 0, 0, 0.87)',
                        marginRight: 2,
                        marginTop: 5,
                        width: 120
                    }}
                    onClick={onCalculatorClick}
                >
                    Calculator
                </Button>
            </div>
        </Box>
    );
}